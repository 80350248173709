<template lang="html">
  <div id="home-page">

    <!-- Banners  -->
    <section class="container-fluid banner-section">
      <swiper class="swiper-desktop" :options="bannersOption">
        <swiper-slide v-for="(banner, index) in banners.pc" :key="index">
          <a class="placed-backg" :href="banner.url ?  banner.url : '#'" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }">
            <img src="public/images/pages/home/banner.png">
          </a>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <swiper class="swiper-mobile" :options="bannersOption">
        <swiper-slide  v-for="(banner, index) in banners.movil" :key="index">
           <a class="placed-backg" :href="banner.url ?  banner.url : '#'" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }">
             <img src="public/images/pages/home/banner-m.png">
           </a>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>
    <!--  -->

    <!-- Productos | Lo + visto -->
    <section class="container oversized-container mb-4 products-section" v-if="products_mostview.length">
      <div class="row">
        <div class="col-12 col-sm-title">
          <h2 class="title">Lo + visto</h2>

          <p>
            Nuestra misión es, abastecer con nuestra gama de productos a nuestros clientes y futuros distribuidores.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-12 px-0 col-products">
          <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
            <swiper-slide v-for="(product, index) in products_mostview" :key="index">
              <div class="col-12 px-0 sample-product shad-prod">
                <router-link class="outlined-box" :to="'/producto/'+$root._converToURL(product.name, product.id)">
                  <div class="box-image">
                    <div class="out-of-stock" v-if="product.stock < 1">Agotado</div>

                    <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.imageUrl+')' }">
                      <img src="public/images/shared/empty.png" >
                    </div>
                  </div>

                  <div class="box-shot-descr">
                    <h6 class="brand">{{ product.sku }}</h6>
                    <h6 class="name">{{ product.name }}</h6>
                    <h6 class="brand">{{ product.brand }}</h6>
                    <h6 class="price discount" v-if="product.discount"><span>{{ product.discount }}</span></h6>
                    <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h6>
                  </div>
                  <p class="p-button">
                    <a class="btn-more">Ver más</a>
                  </p>
                </router-link>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>
    <!--  -->

    <!-- Promociones -->
    <section class="promotions-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 col-sm-title">
            <h2 class="title">Promociones</h2>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12">
            <swiper class="swiper" :options="promoOptions">
              <swiper-slide v-for="(p, pInx) in promos" :key="'pInx-'+pInx">
                <div class="box-promo" @click="openPromoModal(p.imageUrl)">
                  <img :src="p.imageUrl">
                </div>
              </swiper-slide>

              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>
    <!--  -->

    <!-- Mision & Visión -->
    <MissionComponent></MissionComponent>
    <!--  -->

     <!-- Multibanners -->
    <!-- <section class="container oversized-container multi-banners-section">
      <swiper class="swiper" :options="multibanersOptions">
        <swiper-slide v-for="(banner, bxhInx) in banner_middle" :key="'bxhInx-'+bxhInx">
          <div class="col-12 px-0 col-banner">
            <a :href="banner.url">
              <img :src="banner.imageUrl">
            </a>
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>  -->

    <!-- Simple banner -->
    <section class="simple-banner-section">
      <router-link class="img-desktop" to="">
        <img src="public/images/pages/home/banner-e-1.jpg">
      </router-link>

      <router-link class="img-mobile" to="">
        <img src="public/images/pages/home/banner-e-1-m.jpg">
      </router-link>
    </section>
    <!-- Simple banner -->

    <!-- Productos | Populares -->
    <section class="container oversized-container mb-4 products-section">
      <div class="row">
        <div class="col-12 col-sm-title">
          <h2 class="title">Populares</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12 px-0 col-products">
          <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
            <swiper-slide v-for="(product, index) in products_popular" :key="index">
              <div class="col-12 px-0 sample-product shad-prod">
                <router-link class="outlined-box" :to="'/producto/'+$root._converToURL(product.name, product.id)">
                  <div class="box-image">
                    <div class="out-of-stock" v-if="product.stock < 1">Agotado</div>

                    <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.imageUrl+')' }">
                      <img src="public/images/shared/empty.png" >
                    </div>
                  </div>

                  <div class="box-shot-descr">
                    <h6 class="brand">{{ product.sku }}</h6>
                    <h6 class="name">{{ product.name }}</h6>
                    <h6 class="brand">{{ product.brand }}</h6>
                    <!-- <h6 class="price discount"><span>$ {{ product.price }}</span></h6> -->
                    <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h6>
                  </div>
                  <p class="p-button">
                    <a class="btn-more">Ver más</a>
                  </p>
                </router-link>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>
    <!--  -->

    <!-- Customers -->
    <!-- <div class="customers-section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-title">
            <h2 class="title">Nuestros clientes</h2>
          </div>

          <div class="col-lg-4 col-logo">
            <img src="public/images/pages/about-us/mercedes-benz.png">
          </div>

          <div class="col-lg-4 col-logo">
            <img src="public/images/pages/about-us/volvo.png">
          </div>

          <div class="col-lg-4 col-logo">
            <img src="public/images/pages/about-us/volkswagen.png">
          </div>
        </div>
      </div>
    </div> -->
    <!--  -->

    <!-- Blog -->
    <!-- <section class="container oversized-container pb-4 brands-section" v-if="blogs.length">
      <div class="row">
        <div class="col-12 col-s-title">
          <h2 class="title">Posts destacados</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-lg-4 col-xl-3 col-article-sample-1" v-for="(blog, baInx) in blogs" :key="'ba-'+baInx">
          <router-link class="box-article" :to="'/blog/'+$root._converToURL(blog.title, blog.id)">
            <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+blog.imageUrl+')' }">
              <img src="public/images/pages/blog/empty.png">
            </div>

            <div class="box-descr">
              <h5>{{ blog.title }}</h5>
              <div class="descr" v-html="blog.body"></div>
            </div>
          </router-link>
        </div>
      </div>
    </section> -->
    <!--  -->

    <!-- Modal de imagen con publicidad -->
    <b-modal ref="modal-promo" size="xl" modal-class="modal-home-promo" centered hide-footer title="">
      <img :src="modalImage">
    </b-modal>
    <!--  -->

  </div>
</template>

<script>
import MissionComponent from './about-us/mission-component.vue';
export default {
  components: {
    MissionComponent
  },

  data(){
    return{
        banners:{
            pc:[],
            movil:[]
        },
        banner_middle:[],
        products_mostview:[],
        products_popular: [],
        promos: [
          { imageUrl: 'public/images/pages/home/promo-1.jpg' },
          { imageUrl: 'public/images/pages/home/promo-1.jpg' },
          { imageUrl: 'public/images/pages/home/promo-1.jpg' },
          { imageUrl: 'public/images/pages/home/promo-1.jpg' },
        ],
        modalImage: null,

        /* == Carousel options == */
        bannersOption: {
            loop: true,
            speed:800,
            effect: 'fade',

            pagination: {
            el: '.swiper-pagination',
            dynamicBullets: true,
            clickable: true
            },

            autoplay: {
              delay: 3000,
              disableOnInteraction: false
            },
        },

        productsOption: {
            loop: false,
            slidesPerView: 5,
            spaceBetween: 10,

            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },

            breakpoints: {
              1399: {
                  slidesPerView: 5
              },
              1199: {
                  slidesPerView: 4
              },
              991: {
                  spaceBetween: 10,
                  slidesPerView: 3
              },
              575: {
                  spaceBetween: 20,
                  slidesPerView: 2
              },
              1: {
                  slidesPerView: 1
              },
            },
            autoplay: {
              delay: 5000, // Cambia el tiempo de retardo según lo que necesites
              disableOnInteraction: false
            },
            effect: 'slide', // Puedes cambiarlo a 'fade', 'cube', 'coverflow', etc.
            speed: 600 // La velocidad de la transición en milisegundos
        },

        multibanersOptions: {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 30,

            navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
            },

            breakpoints: {
            1400: {
                slidesPerView: 4
            },
            992: {
                slidesPerView: 3
            },
            768: {
                slidesPerView: 2
            },
            1: {
                slidesPerView: 1
            },
            },
        },

        promoOptions: {
          slidesPerView: 3,
          spaceBetween: 20,

          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },

          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },

          breakpoints: {
            1200: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
          }
        }
        // == ==
        }
    },

    methods: {
      getHome: function(){
        axios.get(tools.url('/api/home')).then(({data}) =>{
          this.banners = data.banners;
          this.products_mostview = data.mostview;
          this.products_popular = data.popular;

          this.banner_middle = data.banners.pc;
        });
      },

      openPromoModal(imageUrl) {
        this.modalImage = imageUrl;
        this.$refs['modal-promo'].show();
      },
    },

    mounted(){
      this.getHome();
    },
}
</script>
